#player-main-container{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #5250B4;
   position: relative;
   overflow: hidden;
   padding-top:56.25%
   /* padding-bottom: 56.25%; */
}

.react-player{
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   position:absolute;
}