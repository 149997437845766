.btn.btn-primary{
   background-color: #5250B4;
   border-color: #5250B4;
 }
 
 .btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus{
   background-color: #6b69f3;
   border-color: #6b69f3
 }
 
 .btn.btn-outline-primary{
   border-color: #5250B4;
   color:#5250B4;
 }
 
 .btn.btn-outline-primary:hover, .btn.btn-outline-primary:active, .btn.btn-outline-primary:focus{
   background-color: #5250B4;
   border-color: #5250B4;
   color:white;
 }