#development-main-container{
   height: 100vh;
   background-image: url(../../../assets/background/developmentBGF.png);
   background-repeat: no-repeat;
   background-size: cover;
   background-color: #5250B4;
   padding: 10px 10%;
   position:relative;
   display:flex;
   justify-content: space-between;
   flex-direction: column;
}

#development-background{
   position: absolute;
   transform: translate( 0%, -50%);
   right: 0;
   top: 50%;
   z-index: 10;
}


.development-flex-container{
   display: flex;
   align-items:center;
   justify-content:space-between;
   width: 100%;
   height: 100%;
   margin-bottom: 50px;
}

.flex-item-left{
   flex-basis: 49%;
   display:flex;
   justify-content: flex-center;
}

.flex-item-right{
   flex-basis: 49%;
   display:flex;
   justify-content: center;
}

#development-title{
   color: white;
   font-weight: bold;
}


#development-title-separator{
   border-bottom: 1px solid white;
   z-index: 100;
   width: 200px;
   margin-bottom: 50px;
}


#development-subtitle{
   color: white;
   word-wrap: break-word;
   width: 250px;
}


#development-legend{
   margin: 75px 0;
}


#development-progress-bar{
   height: auto;
   max-width: 500px;
   width: 100%;
   min-width: 280px;
}


#development-item{
   height: auto;
   width: 100%;
   flex-shrink: 1;
   flex-grow:1;
   flex-basis: 100%;
   min-width: 250px;
   width: 350px;
   max-width: 1250px;
}


#development-legend div h5{
   color: white;
}

#development-legend div {
   margin: 30px 0;
}

#development-indicator-text{
   color: white;
}

#titleContainer{
   justify-content: flex-end;
}




@media screen and (max-width: 1080px){
   #development-main-container{
      height: 110vh;
   }
   

   .flex-item-right{
      order: 1;
      margin: 10px;
      flex-shrink: 1;
   }

   .flex-item-left{
      padding-top: 4%;
      order: 2;
   }
   
   #development-item{
      width: 100%;
      min-width:280px;
   }
}

@media screen and (max-width: 768px){
   #development-main-container{
      height: 120vh
   }
   #development-item{
      width: 350px;
      min-width: 280px;
   }

   .flex-container{
      justify-content: space-around;
   }

   .flex-item-left{
      justify-content: center;
   }
}


@media screen and (max-width: 464px){
   #development-item{
      height: auto;
      width: 270px;
      min-width: 260px;
   }

   .flex-item-right{
      flex-basis: 25%;
   }
   .flex-item-left{
      flex-basis: 25%;
   }
   .development-flex-container{
      margin-bottom: 165px;
   }
   
}



/* Height */
@media screen and (max-height: 970px){
   #development-main-container{
      height: max-content;
      padding: 8% 10%;
   }
}
/* 
@media screen and (max-height: 768px){
   #development-main-container{
      height: 230vh !important;
   }
}


@media screen and (max-height: 464px){
   #development-main-container{
      height: 350vh !important;
      padding-top: 5%;
   }
} */
