#register-main-container{
   background-color: #D2D2D2;
   height: 100vh;
   padding: 10px 80px;
   position: relative;
   background-image: url(../../../assets/background/registerBGF.png);
   background-repeat: no-repeat;
   background-size: cover;
}

#register{
   display: flex;
   justify-content: flex-end;
   align-items: center;
   height: 100%;
   width: 100%;
}


#register-background{
   transform: translate( 0%, -50%);
   top: 50%;
   left: 0;
   z-index: 10;
   position: absolute;
}


#register-terms{
   word-wrap: break-word;
   max-width: 320px;
}

#register-title{
   margin-bottom: 40px;
   font-weight: bold;
   font-size: 2em;
}

#register-form-container{
   flex-basis: 50%;
}

#register-title-container{
   margin: 40px 10px;
}

#register-submit-privacy{
   display: flex;
   justify-content:flex-start;
   align-items:flex-start;

}

/* @media screen and (max-width 768px){

} */


@media screen and (max-width: 1080px){
   #register{
      justify-content: center;
      align-items: center;
   }

   #register-form-container{
      flex-basis: 95%;
   }
}

@media screen and (max-width: 464px){
   #register-main-container{
      padding: 10px 20px;
      height: 100vh;
   }

   #register-submit-privacy{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
   }

   #register-submit-privacy button{
      margin: 10px 0 25px 0;
   }
}




/* Height */
@media screen and (max-height: 970px){
   #register-main-container{
      height: max-content;
      padding: 8% 10%;
   }
}
/* 
@media screen and (max-height: 768px){
   #register-main-container{
      height: 130vh !important;
      padding-top: 5%;
   }
}


@media screen and (max-height: 464px){
   #register-main-container{
      height: 350vh !important;
      padding-top: 5%;
   }
} */