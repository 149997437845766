.header{
   padding: 20px 10% !important;
   flex:1;
   align-items: center;
   justify-content: space-between !important;
   z-index: 1000;
   background-color: white;
   width: 100%;
 }
 #desktopHeader{
   display: block;
 }
 
 #mobileHeader{
   display:none;
 }

 /* Nav Start*/
.nav-link.active{
   border-radius: 30px;
   background-color: #5250B4;
   color: white !important;
 }
 .nav-link{
    margin: 0 20px;
    font-weight: 600;
    padding:10px 20px 10px 20px !important;
 }
 /* Nav End */

 @media screen and (max-width: 941px){
    #desktopHeader{
       display: none;
    }

    .header{
      z-index: 1001;
    }

    #mobileHeader{
       display:inline-block;
       z-index: 1001;
    }

    .header{
      top:0;
      left:0;
      position: fixed !important;
      /* width: 85%; */
      padding:30px 40px !important;

    }
 }


 @media screen and (max-width:768px){
   .header{
     padding:15px 60px !important;
     /* width: 95%; */
    }
 }

 @media screen and (max-width:464px){
    .header{
     padding:15px 30px !important;
     /* width: 90%; */
  }
   #header-logo{
     width: auto;
     height: 35px;
   }
 }

