.sidebar-mask{
   background-color: rgba(0, 0, 0, 0.664);
   position:fixed;
   top:0;
   left:0;
   width: 100%;
   height: 100%;
   z-index: 200;
}



.sidebar-body{
   position: fixed;
   background-color: white;
   top:0;
   left: 0;
   height: 100%;
   width: 20%;
   min-width: 280px;
   z-index: 200;
   padding: 30px;
}


.sidebar-items_container{
   margin: 30px 0px;
}

.sidebar-items_container .nav-item{
   margin: 5px 0px;
}