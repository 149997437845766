/* Main Page */


#main-container{
   display:flex;
   flex-direction:column;
   justify-content: space-between;
   padding: 10px 10%;
   height: 100vh;
   position:relative;
 }
 #main-title-buttons{
    flex-basis: 49%;
    flex-shrink:1;
 }
 #textDiv{
   flex-basis: 49%;
   flex-shrink: 1;
   display:flex;
   justify-content: flex-start;
 }
 
 #second-container{
   display:flex;
   /* flex-wrap: wrap; */
   justify-content: space-between;
   align-items: center;
   width: 100%;
   height: 100%;
   padding-bottom: 10%;
 }
 #second-container div h1{
   /* text-align: start; */
   font-size:4em;
   width:100%;
 }
 #mainPage-item {
   flex-shrink: 1;
   flex-grow: 1;
   height: auto;
   min-width: 250px;
   width: 500px;
   max-width: 900px;
 }
 #main-page-buttons-largeScreen{
    padding-top: 3%;
    display:flex;
    justify-content: flex-start;
    align-items:center;
    width: 100%;
 }
 
 .main-page-buttons{
   display: none;
 
 }
 
 .main-page-buttons > button{
   margin: 5px 0 ;
 }




 
 /* Main End */
   /* @media screen and ( max-width: 1536px){
      #second-container div h1{
         text-align: start;
         font-size:2.8em;
         width:70%;
      }
      #mainPage-item{
         padding:2% 0;
         min-width: 360px;
      }
   } */

   @media screen and ( max-width: 1080px){
      #second-container{
         /* flex-wrap: wrap; */
         justify-content: center;
         align-items: center;
      }

      #second-container div h1{
         /* text-align: start; */
         font-size:3em;
         width:70%;
      }
      
      #mainPage-item{
         padding:8% 0;
         width: 250px;
         min-width: 360px;
      }
   }

   @media screen and (max-width: 950px){
      #main-container{
         padding: 23% 10%;
       }
   }

   @media screen and (max-width: 768px ){


      #second-container{
         flex-direction: column;
         justify-content: center;
         align-items:center;
         flex-wrap:nowrap;
         width:100%;
      }
      #textDiv{
         display:flex;
         flex-basis: auto;
         justify-content:flex-start;
         width: 100%;
         height: max-content;
         padding-left: 15%;
      }
      #main-title-buttons{
         flex-shrink:1;
         flex-basis: auto;
         height: max-content;
      }
      #second-container div h1{
         /* text-align: start; */
         font-size:2.1em;
         width:100%;
      }
      #main-page-buttons-largeScreen{
         display: none;
      }

      #mainPage-item{
         min-width: 380px;
      }

      .main-page-buttons{
         display:flex;
         flex-wrap:wrap;
         justify-content:center;
         align-items:center;
         height: max-content;
      }


}


@media screen and (max-width:464px){
   #second-container div h1{
      text-align: start;
      padding: 0;
   }
   #textDiv{
      padding-left: 3%;
   }
   #main-container{
      padding:50% 20px;
   }
   #mainPage-item{
      min-width: 250px;
   }

   .main-page-buttons button{
      /* font-size: 0.5em!important; */
      white-space: nowrap;
   }
}


/* Height */


@media screen and (max-height: 1079px){
   #main-container{
      height: max-content;
   }
}


/* 
@media screen and (max-height: 857px){
   #main-container{
      height: 120vh;
   }
}

@media screen and (max-height: 635px){
   #main-container{
      height: 115vh;
      padding-top: 5%;
   }
}


@media screen and (max-height: 464px){
   #main-container{
      height: 230vh;
      padding-top: 5%;
   }
} */
