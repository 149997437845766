#features-main-container{
   height: 100vh;
   background-image: url(../../../assets/background/featuresBGF.png);
   background-repeat: no-repeat;
   background-size: cover;
   background-color: #E5E5E5;
   padding: 10px 10%;
   position:relative;
   display:flex;
   justify-content:space-between;
   flex-direction: column;
}


#features{
   display: flex;
   align-items: center;
   flex-wrap:wrap;
   justify-content: space-between;
   width: 100%;
   height: 100%;
}


#features-background{
   position: absolute;
   left:0;
   bottom:0;
   z-index:10;
}

#features-title{
   color:#5250B4;
   padding-bottom: 10px;
   font-weight: bold;
   font-size: 3.5em;
}


#features-subtitle{
   width: 350px;
   font-size: 1.3em;
}


#features-list{
   display: flex;
   flex-wrap:wrap;
   justify-content:flex-end;
   align-items:center;
   flex-basis: 60%;
   /* justify-self: flex-start; */
}

#features-list-item{
   display: flex;
   align-items:center;
   justify-content: flex-start;
   flex-basis: 49%;
   margin-top: 5%;
}

#features-list-icons{
   font-size: 2.2em;
   font-weight:bold;
}

#features-list-text{
   word-wrap: 'word-break';
   font-size: 1.3em;
   font-weight: 500;
}

@media screen and (max-width: 1366px){
   #features-title{
      color:#5250B4;
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 2.5em;
   }
   
   
   #features-subtitle{
      width: 250px;
      font-size: 1em;
      font-weight: 500;
   }

   #features-list-icons{
      font-size: 1.8em;
      font-weight:bold;
   }
   
   #features-list-text{
      word-wrap: 'word-break';
      font-size: 1em;
      font-weight: 500;
   }
}


@media screen and (max-width: 1024px){
   #features-title{
      color:#5250B4;
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 2.5em;
      text-align: center;
   }
   
   #features-title-separator{
      width: 100%;
   }
   
   #features-subtitle{
      width: 450px;
      font-size: 1.2em;
      font-weight: 500;
   }


   #features{
      flex-direction: column;
      justify-content:center;
   }

   #features-list{
      justify-content:flex-start;
      flex-basis: 60%;
      padding: 5% 0;
   }
}

@media screen and (max-width: 768px){

   #features-main-container{
      padding: 30px 80px;
      height: 130vh
   }

   #features{
      flex-direction: column;
      justify-content: center;
      align-items:center;
      flex-wrap:nowrap;
   }

   #features-list{
      width: 100%;
   }

   #features-list-item{
      flex-basis: 49%;
   }  

   #features-list-icons{
      font-size: 1.7em;
   }

   #features-list-text{
      font-size: .9em;
   }

   #features-title{
      color:#5250B4;
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 2.5em;
      text-align: center;
   }

   #features-title-separator{
      width: 100%;
   }
   
   #features-subtitle{
      width: 400px;
      font-size: 1.2em;
      font-weight: 500;
   }

}


@media screen and (max-width: 675px){
   #features-main-container{
      /* height: max-content; */
      /* padding: 45px 80px; */
      height: 180vh;
   }

   #features{
      align-items:flex-start;
   }

   #features-title{
      font-size: 1.9em;
      text-align: start;
   }

   #features-title-separator{
      width: 40%;
   }

   #features-subtitle{
      width: 350px;
      font-size: 0.97em;
   }

   #features-list{
      width: 90%;
      margin-bottom: 80px;
   }
   
   #features-list-item{
      flex-basis: 100%;
   }
}

@media screen and (max-width: 464px){
   #features-main-container{
      height: 230vh;
   }

   #features-subtitle{
      width: 280px;
   }

   #features-list-icons{
      font-size: 1.4em;
   }

   #features-list-text{
      font-size: .8em;
   }
   #features-list-item{
      margin-top: 14%;
   }
}

@media screen and ( max-width: 320px){
   #features-main-container{
      padding: 45px 30px;
   }
}



/* Height */
@media screen and (max-height: 970px){
   #features-main-container{
      padding: 12% 10%;
      height: max-content;
   }
}
/* 
@media screen and (max-height: 768px){
   #features-main-container{
      height: 230vh !important;
      padding-top: 5%;
   }
}


@media screen and (max-height: 464px){
   #features-main-container{
      height: max-content !important;
      padding-top: 5%;
   }
} */