html, body {
  height: 100%;
  margin:0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App{
  position: relative;
}



.text-highlight{
  color:#5250B4;
}

.background-image{
  width: auto;
  height: 80vh;
}
/* .item-image{
  height:auto;
  width: 00vw;
  max-width: 900px;
  min-width: 200px;
} */

.title-separator{
  border-bottom: 1px solid white;
  z-index: 100;
  width: 200px;
  margin-bottom: 50px;
}
.border-bottom-highlight{
  border-bottom: 1px solid #5250B4;
}

#bottom-container a{
  bottom:0;
  color:#939393;
  margin-bottom: 5%;
  /* position: absolute; */
}
/* Common End */

#bottom-container > a > h3{
  font-weight: 700;
}


.cards {
  border-radius: 5px;
  /* background-image: url('../../../assets/img/mainImage.svg'); */
  background: transparent;
  /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
  transition: box-shadow 0.5s;
  will-change: transform;
  display: flex;
  justify-content: flex-end;
  align-items:center;
  width: max-content;
  z-index: 1000;
  flex-basis: 60%;
}

.cards:hover {
  /* box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4); */
}

.swal2-container .swal2-show{
  overflow: hidden !important;
  height: auto !important;
}

@media screen and ( max-width: 1440px){
  .cards{
      flex-basis: 49%;
  }
}

@media screen and (max-width:1080px){
  .flex-container{
    display:flex;
    justify-content: center;
    flex-direction:column;
    flex-wrap: nowrap;
    align-items:center;
  }
  .flex-item-left, .flex-item-right{
    /* flex-basis: 100% ; */
    display:flex;
    align-items: center;
    justify-content:center;
  }

  /* .item-image{
    min-width: 350px;
  } */

  #bottom-container{
    /* margin: 30px 10px; */
    width: 100%;
  }


}

@media screen and (max-width:768px){
  /* #bottom-container a{
    bottom:0;
    left:50;
    transform: translate(-50%, 0);
    position:absolute;
    color:#939393;
    margin-bottom: 5%;
  } */
  
  /* #desktopHeader{
    display: none;
  }
  #mobileHeader{
    display: inline-block;
  } */
/* 
  .flex-item-left{
   padding-right: 10%;
  } */

  .cards {
    flex-basis: auto;
  }

}

@media screen and (max-width:  464px){
  #bottom-container{
    /* margin: 450px 10px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: unset;
  }

  #bottom-container a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#939393;
    margin: 50px 0;
    position: absolute;
    bottom: 0;
  }
}


/* @media screen and (max-width:768px){
  .header{
    margin:20px;
  }
} */


/* @media screen and (max-width:464px){

  #header-logo{
    width: auto;
    height: 50px;
  }
} */

@import './components/footer/footer.css';
@import './components/sidebar/sidebar.css';
@import './components/header/header.css';
@import './components/form/button.css';


@import './components/desktop/main/mainStyle.css';
@import './components/desktop/player/playerStyle.css';
@import './components/desktop/features/featuresStyle.css';
@import './components/desktop/development/developmentStyles.css';
@import './components/desktop/register/registerStyles.css';