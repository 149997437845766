/* Footer  */
#footer-main-container{
   padding: 40px 80px 40px 80px;
 }

 #footer-inner{
   display: flex;
   justify-content:space-between;
   align-items:center;
 }

@media screen and (max-width: 565px){
  #footer-inner{
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
  }

  #footer-inner h6{
    text-align: center;
    font-size: .7em;
    margin: 10px 0px;
  }
}